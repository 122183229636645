import React from "react"

const Footer = () => (
  <footer id="footer" className="black-bg vertical-padding-small">
		<div className="container">
			<div className="horizontal-padding">
        Mazda Foundation<br/>
        Private Bag 40, Mount Waverley BC Vic 3149<br/>
        Telephone: (03) 8540 1800<br/>
        Email: info@mazdafoundation.org.au
      </div>
		</div>
	</footer>
)

export default Footer