import React from "react"
import Header from "./header"
import Footer from "./footer"
import "./layout.css"
import "../style/custom.css"

const Layout = ({ page, children }) => {
  return (
    <>
      <Header page={page} />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
