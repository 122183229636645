import React, { useState } from "react"
import { Link } from "gatsby"
import Logo from "../assets/logo.png"

const Header = ({ page }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  
  return (
    <header id="header" className="header clearfix">
      <div
        id="mobile-nav"
        onClick={e =>
          setMenuOpen(!menuOpen)
        }
        style={{ backgroundColor: (page === "events") ? "#e6e6e6" : "#66cc99" }}
        className={`${menuOpen ? "active" : ""}`}     
        >
        <span />
        <span />
        <span />
      </div>
      <div className="header-wrapper">
        <div className="four columns">
          <div className="logo">
            <Link to="/">
              <img
                src={Logo}
                alt="Mazda Foundation" className="logo_standard" />
            </Link>
          </div>
        </div>

        <div id="navigation" className={`columns ${menuOpen ? "active" : ""}`}>
          <div className="menu-primary-navigation-container">
            <ul id="nav" className="menu">
              <li className={`menu-item ${page === "home" ? "active" : ""}`}>
                <Link to="/">Home</Link>
              </li>
              <li className={`menu-item ${page === "about" || page === "bod" ? "active" : ""} menu-item-has-children`}>
                <Link to="/about">About</Link>
                <ul className="sub-menu">
                  <li className={`menu-item ${page === "bod" ? "active" : ""}`}>
                    <Link to="/about/board-of-directors">Board of Directors</Link>
                  </li>
                </ul>
              </li>
              <li className={`menu-item ${page === "objectives" ? "active" : ""}`}>
                <Link to="/foundation-objectives">Foundation Objectives</Link>
              </li>
              <li className={`menu-item ${page === "funding" ? "active" : ""}`}>
                <Link to="/obtaining-funding">Obtaining Funding</Link>
              </li>
              <li className={`menu-item ${page === "reports" ? "active" : ""}`}>
                <Link to="/report-to-the-community">Reports</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header